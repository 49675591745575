:root {
  --black: #1f1f1f;
  --darkgrey: #595959;
  --mediumgrey: #a5a5a5;
  --lightgrey: #cccccc;
  --white: #f2f2f2;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  background-color: var(--black);
  text-align: center;
}
body {
  color: var(--lightgrey);
  font-family: Helvetica, Arial, sans-serif;
}

/* Project List */
.single-project {
  color: var(--darkgrey);
  position: relative;
  margin: 10px;
  -webkit-transition: all 500ms;
}
.single-project a {
  color: var(--darkgrey);
  -webkit-transition: 250ms;
}
.single-project a:hover {
color: var(--white);
text-decoration: none;
-webkit-transition: 500ms;
}
.project-image {
width: 100%;
height: 250px;
box-shadow: 0 0 5px black;

border-radius: 2%; 
object-fit: cover;
object-position: top;
-webkit-transition: 250ms;
}
.project-image:hover, .project-image:focus, .project-image:active {
  box-shadow: 0 0 20px 5px var(--lightgrey);
  -webkit-transition: box-shadow 500ms;
}
.single-project:hover, .single-project:focus, .single-project:active{
  color: var(--white);
  transform: scale(1.1);
  z-index: 1;
  -webkit-transition: all 500ms;
}

/* Home */
.me {
  max-width: 40%;
  margin: 20px
}
#homeBio {
  margin: 20px;
  font-size: 35px;
}

/* About Me */
.me2 {
  max-width: 60%;
  margin: 40px;
  box-shadow: 0 0 5px black

}
#aboutBio {
  margin: 20px;
}
.family {
  max-width: 60%;
  margin: 20px;
  box-shadow: 0 0 5px black;
  float: left;
}

/* Nav */
nav a {
  color: var(--darkgrey);
  -webkit-transition: 250ms;
}
nav a:hover, nav a:focus, nav a:active {
  opacity: 50%;
  color: var(--white);
  text-decoration: none;
  -webkit-transition: 500ms;
}

.headers { 
  color: var(--lightgrey);
}

.darkgrey {
  color: var(--darkgrey);
}

/* Footer */
#footer {
  clear: both;
  position: relative;
  height: 200px;
  margin-top: -200px;
}

/* Fade */

.fade-appear {
  opacity: 0;
}

.fade-appear-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.fade-enter {
  opacity: 0;
}

.fade-enter-active {
  opacity: 1;
  transition: opacity 2000ms;
}

.fade-exit {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
  transition: opacity 1000ms;
}
